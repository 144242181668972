import { algoliasearch } from 'algoliasearch'

import { AppCategoriesDocument, CategoryOrder } from '@/generated/contentful'

import { client } from '@/lib/contentful/client'

import { toTitleCase } from '@/utils/string/toTitleCase'

const algoliaClient = algoliasearch(
	process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
	process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || ''
)

// Filters for child categories under current parent category
export function getAlgoliaChildCategoriesFromFacets(facet: Record<string, number> | undefined) {
	if (!facet) return null

	return Object.entries(facet)
		.sort(([a], [b]) => (a ?? '').localeCompare(b ?? ''))
		.map(([parentChildCategory, childAppCount]) => {
			const [parentCategory, childCategory] = parentChildCategory.split(' > ')

			const childCategoryTitle = toTitleCase(childCategory)

			return {
				title: childCategoryTitle,
				slug: childCategory,
				appCount: childAppCount,
				parentCategory: {
					title: toTitleCase(parentCategory),
					slug: parentCategory
				}
			}
		})
}

export async function getAllAppAlgoliaFacets() {
	try {
		const algoliaMarketplaceAppsFacetsRes = await algoliaClient.search({
			requests: [
				{
					indexName: process.env.NEXT_PUBLIC_ALGOLIA_MARKETPLACE_APPS_INDEX || '',
					facets: ['heirarchicalCategories.lvl0', 'tier.title', 'appType'],
					sortFacetValuesBy: 'alpha',
					hitsPerPage: 0
				}
			]
		})

		const algoliaMarketplaceAppsParentCategoryFacet =
			algoliaMarketplaceAppsFacetsRes.results[0].facets?.['heirarchicalCategories.lvl0']

		const algoliaMarketplaceAppsTierFacet =
			algoliaMarketplaceAppsFacetsRes.results[0].facets?.['tier.title']

		const algoliaMarketplaceAppsAppTypeFacet =
			algoliaMarketplaceAppsFacetsRes.results[0].facets?.['appType']

		const tiers = algoliaMarketplaceAppsTierFacet
			? ['All', ...Object.entries(algoliaMarketplaceAppsTierFacet).map(([title]) => title)]
			: null

		const appTypes = algoliaMarketplaceAppsAppTypeFacet
			? ['All', ...Object.entries(algoliaMarketplaceAppsAppTypeFacet).map(([title]) => title)]
			: null

		const facetParentCategories = Object.entries(
			algoliaMarketplaceAppsParentCategoryFacet ?? {}
		).map(([parentCategory, parentCategoryAppCount]) => {
			return {
				slug: parentCategory,
				appCount: parentCategoryAppCount
			}
		})

		const { categoryCollection } = await client().request(AppCategoriesDocument, {
			where: {
				slug_in: [...facetParentCategories.map(({ slug }) => slug)]
			},
			order: [CategoryOrder.NameAsc]
		})

		const parentCategories = facetParentCategories.map(({ slug, appCount }) => {
			const title = categoryCollection?.items?.find((category) => category?.slug === slug)?.name

			return {
				title,
				slug,
				appCount
			}
		})

		return { parentCategories, tiers, appTypes }
	} catch (e) {
		console.error(e)
	}
}
