import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useSearchBox } from 'react-instantsearch';
import type { UseSearchBoxProps } from 'react-instantsearch';
import ArrowDown from 'src/icons/ArrowDown';
import useSWR from 'swr';
import { serializeCacheKey } from '@/lib/contentful/utils';
import { getAllAppAlgoliaFacets } from '../AppSidebar/getAllAppFacets';
import { useAppsCurrentcategories } from '../AppsProvider';
let timerId: NodeJS.Timeout | undefined = undefined;
const queryHook: UseSearchBoxProps['queryHook'] = (query, search) => {
  if (timerId) {
    clearTimeout(timerId);
  }
  timerId = setTimeout(() => search(query), 300);
};
type Props = {
  searchInputPlaceholder?: string;
  className?: string;
};
export function AppsSearch({
  searchInputPlaceholder,
  className
}: Props) {
  const {
    push,
    asPath,
    query
  } = useRouter();
  const {
    data
  } = useSWR(serializeCacheKey('appAlgoliaFacets'), () => getAllAppAlgoliaFacets());
  const {
    query: searchQuery,
    refine
  } = useSearchBox({
    queryHook
  });
  const {
    parentCategory
  } = useAppsCurrentcategories();
  const [inputValue, setInputValue] = useState(searchQuery);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleAppCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectValue = event.target.value;
    if (selectValue === 'all-categories') {
      window.location.href = `${window.location.origin}/apps/categories`;
    }
    window.location.href = `${window.location.origin}/apps/categories/${selectValue}`;
  };
  function setQuery(newQuery: string) {
    setInputValue(newQuery);
    refine(newQuery);
  }
  useEffect(() => {
    setQuery((query.query as string) || '');
    if (query.query) {
      inputRef?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.query]);
  return <div className={clsx('flex flex-col lg:flex-row gap-8', className)} data-sentry-component="AppsSearch" data-sentry-source-file="AppsSearch.tsx">
			<div className="relative flex items-center focus-within:drop-shadow-lg">
				<select className="max-lg:w-full w-[357px] h-full rounded-md py-3 px-4 focus:outline-none border-0 bg-white text-bc-black ring-1 ring-gray-1000 focus:ring-gray-1000 sm:text-sm sm:leading-6 bg-none" onChange={handleAppCategoryChange} value={parentCategory?.slug || 'all-categories'} data-testid="app-category-select">
					<option value="all-categories">All Categories</option>

					{data?.parentCategories?.map((category, i) => {
          if (!category?.title) return null;
          return <option key={i} value={category.slug || ''}>
								{category.title}
							</option>;
        })}
				</select>

				<ArrowDown className="text-gray-900 absolute right-5 pointer-events-none" data-sentry-element="ArrowDown" data-sentry-source-file="AppsSearch.tsx" />
			</div>

			<div id="search-combobox" className="relative focus-within:drop-shadow-lg z-20 flex-grow">
				<input id="combobox" type="search" className="w-full rounded-md border-0 bg-white py-3 pl-6 pr-14 text-bc-black ring-1 ring-gray-1000 focus:ring-gray-1000 sm:text-sm sm:leading-6 [&::-webkit-search-cancel-button]:hidden peer" role="combobox" aria-controls="options" aria-expanded="false" placeholder={searchInputPlaceholder} value={inputValue} ref={inputRef} onChange={event => {
        const inputVal = event.currentTarget.value;
        setQuery(inputVal);
        if (query.page) delete query.page;
        if (!asPath.includes('/apps/categories')) {
          if (timerId) {
            clearTimeout(timerId);
          }
          timerId = setTimeout(() => push({
            pathname: '/apps/categories/',
            query: {
              query: inputVal || []
            }
          }), 700);
        } else {
          push({
            query: {
              ...query,
              query: inputVal || []
            }
          }, undefined, {
            shallow: true
          });
        }
      }} data-testid="search-by-query-input" />

				<button type="button" className="absolute inset-y-0 right-6 focus:outline-none pointer-events-none peer-focus:hidden">
					<svg className="h-4 w-4 text-gray-900" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-source-file="AppsSearch.tsx">
						<path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="AppsSearch.tsx"></path>
					</svg>
				</button>
			</div>
		</div>;
}